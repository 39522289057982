<template>
  <div class="container">
    <div class="row mb-4">
      <div class="col-sm-6">
        <img
          src="../../assets/images/logo-valorem-pay.png"
          title="Valorem Pay"
          class="dimensao-logo"
        />
      </div>
      <div class="col-sm-6 text-right" v-if="logo">
        <img
          :src="logo"
          class="mw-100 dimensao-logo"
          :alt="nomeFantasia"
          :title="nomeFantasia"
        />
      </div>
    </div>
    <div class="centro"></div>
    <div class="row mb-4">
      <div class="col-sm-12 text-center">
        <p class="destaque1">
          {{ $t(mensagem) }}
        </p>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-sm-12 text-center">
        <p class="destaque2">
          {{ $t("CHECKOUT.PAGAMENTO_NEGADO") }}
        </p>
      </div>
    </div>

    <div class="row" id="comprador">
      <div class="col-12 col-md-12">
        <div class="tagComprador label-comprador">
          {{ $t("CHECKOUT.DADOSPAGADOR") }}
        </div>
        <div class="col-12 col-md-12">
          <span class="tagComprador"
            >{{ $t("CHECKOUT.NOME") }} {{ $t(nomeComprador) }}</span
          >
          <span v-if="documentoComprador" class="tagComprador"> | </span>
          <em v-if="documentoComprador" class="tagComprador">
            {{ $t(documentoComprador) }}
          </em>
          <span v-if="telefoneComprador" class="tagComprador">
            &nbsp;| {{ $t(telefoneComprador) }}</span
          >
        </div>
      </div>
      <div class="col-12 col-md-12">
        <div class="tagComprador label-comprador">
          {{ $t("CHECKOUT.TENTATIVA_PAGAMENTO") }}
        </div>
        <div class="col-12 col-md-12">
          <span class="tagComprador">{{
            $t("CHECKOUT.VALORCONFIRMACAO") +
            $t(valor) +
            $t("CHECKOUT.EM") +
            $t(nrParcelas) +
            $t("CHECKOUT.PARCELAS")
          }}</span>
        </div>
      </div>
      <div class="col-12 col-md-12">
        <div class="tagComprador label-comprador">
          {{ $t("CHECKOUT.MENSAGEM_OPERADORA") }}
        </div>
        <div class="col-12 col-md-12">
          <span class="tagComprador">{{ $t(mensagemOriginal) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import CheckoutService from "@/common/services/checkout/checkout.service";

export default {
  data() {
    return {
      id: null,
      logo: null,
      urlRedirecionamento: null,
      nomeComprador: null,
      documentoComprador: null,
      telefoneComprador: null,
      mensagem: "",
      mensagemOriginal: "",
      nomeFantasia: null,
      valor: null,
      nrParcelas: null,
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.ASSINATURAS") },
    ]);
    const urlParams = new URLSearchParams(window.location.search);
    this.id = urlParams.get("id");

    this.buscar();
  },
  methods: {
    buscar: function () {
      this.$store.dispatch(START_LOADING);
      CheckoutService.pagamentoNegado(this.id)
        .then((res) => {
          this.logo = res.data.arquivoLogo;
          this.urlRedirecionamento = res.data.urlRedirecionamento;
          this.mensagem = res.data.mensagem;
          this.mensagemOriginal = res.data.pagamentoMensagem;
          this.nrParcelas = res.data.numeroDeParcelas;
          this.nomeFantasia = res.data.nomeFantasia;
          this.cnpj = res.data.cNPJEstabelecimento;
          this.valor = res.data.valor;
          this.nomeComprador = res.data.dadosPagador.nome;
          this.documentoComprador = res.data.dadosPagador.cpfcnpj;
          this.telefoneComprador = res.data.dadosPagador.telefone;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    fecharAba() {
      window.close();
    },
    finalizar() {
      if (this.urlRedirecionamento)
        window.location.href = this.urlRedirecionamento;
      else this.alertaSucesso(this.$t("CHECKOUT.MENSAGEM_SUCESSO"), false);
    },
    erroPagamento() {
      const nextURL = window.location.origin + "/checkout-erro";
      window.location.href = nextURL;
    },
  },
};
</script>
  <style scoped>
.container {
  margin-top: 8rem !important;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #5f5f5f;
  padding: 20px;
}
.destaque1 {
  font-size: 1rem;
  color: #ef6767;
  font-weight: bold;
}
.destaque2 {
  font-size: 1.5rem;
  color: #ef6767;
  font-weight: bold;
}
.modal-dialog {
  max-width: 720px !important;
}
.dimensao-logo {
  max-width: 106px;
  max-height: 41px;
}
.tagComprador {
  color: rgb(130, 130, 130) !important;
  font-size: 0.8rem !important;
}
.tag-link-expirado {
  color: #ef6767;
  font-size: 1.2rem !important;
  text-align: center;
  font-weight: bold;
}
.tagTaxas {
  color: #ef6767 !important;
  font-size: 0.8rem !important;
  text-align: right;
}
.centro {
  text-align: center;
  margin: 0 auto;
}
.relogio {
  max-width: 32px;
}
</style>